import { useRouter } from 'next/router';
import { graphql } from 'relay-runtime';
import styled from '@emotion/styled';
import { Link } from '@pafcloud/base-components';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { AspectRatioValues, MonetaryDetailsWrapper } from '@pafcloud/game-components';
import { Breakpoint } from '@pafcloud/style';
import { upperCase } from '@pafcloud/util';
import { HeroColors } from './hero-colors';
import type { focusContentHero_content$data } from './__generated__/focusContentHero_content.graphql';

graphql`
  fragment focusContentHero_content on FocusContentHeroFields {
    focusContent {
      ... on FocusContentHeroGame {
        launchUrl
        name
        gameTags
        jackpot {
          amount
        }
        fallbackImage {
          url
        }
      }
      ... on FocusContentHeroPage {
        slug
      }
      ... on FocusContentHeroBonus {
        programExternalId
      }
      ... on FocusContentHeroPromotion {
        promotionId
        promotionSlug
        customPromotionTitle
      }
      ... on FocusContentHeroTag {
        slug
      }
    }
    ctaButtonText
    sellingText {
      jsonContent {
        json
      }
    }
  }
`;

export const useCreateHref = (focusContent: focusContentHero_content$data['focusContent'] | undefined) => {
  const { asPath } = useRouter();

  if (focusContent?.slug != null) {
    return focusContent.slug;
  }

  if (focusContent?.launchUrl != null) {
    return focusContent.launchUrl;
  }

  if (focusContent?.programExternalId != null) {
    return `${asPath}?flowType=bonus&programExternalId=${focusContent?.programExternalId}`;
  }

  if (focusContent?.promotionId != null) {
    if (focusContent.promotionSlug != null) {
      return `${asPath}?flowType=promotion&promotionSlug=${focusContent?.promotionSlug}`;
    }

    return `${asPath}?flowType=promotion&promotionId=${focusContent?.promotionId}`;
  }
};

const useBigScreenBreakpoint = $buildEnv.theme === 'golden' || $buildEnv.theme === 'quirky';
const appNameBreakPoint = useBigScreenBreakpoint ? Breakpoint.BigScreenOrLarger : Breakpoint.LaptopOrLarger;

export const prettifyHeroTagName = (tag: string) => {
  return tag.split('-').map(upperCase).join(' ');
};

export const PictureLink = styled(Link)({
  '--aspect-ratio': AspectRatioValues.landscape.ratio,
  '--radius': '8px',
  '--frame-border-offset': '3px',
  position: 'relative',
  width: '100%',
  borderRadius: 'var(--radius)',
  backgroundClip: 'padding-box',
  height: 'fit-content',

  '::before': {
    content: '""',
    position: 'absolute',
    display: 'inline-block',
    background: HeroColors.FocusContent.FrameBorder,
    borderRadius: 'calc(var(--radius) + var(--frame-border-offset))',
    inset: 'calc(var(--frame-border-offset) * -1)',
  },

  [appNameBreakPoint]: {
    width: '50%',
  },

  'img, video': {
    borderRadius: 'var(--radius)',
  },
  video: {
    position: 'relative',
    width: '100%',
    height: '100%',
    verticalAlign: 'middle',
  },
});

export const ContentSection = styled.section({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingBottom: 16,

  [appNameBreakPoint]: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'start',
    paddingBottom: 0,
  },
});

export const JackpotOverlay = styled(MonetaryDetailsWrapper)({
  top: 'calc(var(--frame-border-offset) * -2)',
  borderRadius: 8,
  alignItems: 'center',
});

export const Content = styled.div({
  margin: 'auto',
  padding: '16px 0',
  width: '100%',

  [appNameBreakPoint]: {
    padding: 24,
    width: '50%',
  },
});

export const GameTags = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  flexWrap: 'wrap',
  marginBottom: 16,
});

export const GameTag = styled.span({
  fontWeight: 'bold',

  ':not(:last-of-type)::after': {
    content: '""',
    verticalAlign: 'middle',
    display: 'inline-flex',
    height: '1.5ch',
    width: 1,
    backgroundColor: HeroColors.FocusContent.GameTagSeparator,
    margin: '0 8px',
  },
});
